.nav-content {
  display: flex;
  justify-content: left;
  padding-left: 30px;
}

.nav-title {
  font-weight: bold;
}

.title {
  font-weight: 500 !important;
  font-size: 26px;
}

.urls-container {
  margin-bottom: 6px;
}

.spinner-blue-grey {
  border-color: #34515E;
}

.preloader-wrapper.mini {
  width: 25px;
  height: 25px;
}

.no-detections-text {
  padding-left: 41px;
}
