input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

.filter-field {
  color: white;
}

.filters-form {
  padding-left: 30px;
}

.detection-type-select {
  display: block;
  text-align: center;
  background-color: #212121;
  color: white;
  position: 'relative';
  top: '1px'
}
